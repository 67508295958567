<template>
  <div v-if="items.length > 0 || activator">
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <v-select
      v-model="selectedItems"
      :name="name"
      :items="items"
      :value="value"
      item-text="itemText"
      item-value="itemValue"
      :label="$t(label)"
      :hint="$t(hint)"
      :multiple="multiple"
      :color="color"
      :required="required"
      chips
      deletable-chips
      :persistent-hint="pHint"
      :class="{ 'mb-6': hint || error }"
      return-object
      error-count="5"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      :menu-props="{ closeOnContentClick: true }"
      :no-data-text="$t(noDataText)"
      :disabled="!editable"
    >
      <template v-slot:selection="{ attrs, on, item }">
        <v-chip v-bind="attrs" close @click:close="deleteFile(item)" v-on="on">
          {{ item.itemText }}
        </v-chip>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <template>
          <v-list-item v-bind="attrs">
            <v-list-item-content>
              <v-list-item-title v-html="item.itemText"></v-list-item-title>
            </v-list-item-content>
            <v-spacer />
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon @click="deleteFile(item)" v-on="{ on }">
                  $mdiDelete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-select>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Tooltip from "./Tooltip";
export default {
  name: "FileSelectList",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "multiple",
    "inputHeading",
    "icon",
    "color",
    "hint",
    "tooltip",
    "pHint",
    "required",
    "error",
    "hasError",
    "noDataText",
    "files",
    "confirmDeleteFile",
    "deleteFiles",
    "activate",
    "editable"
  ],
  data() {
    return {
      /**
       * rawItems is a slimmed copy of the former file-object,
       * just containing fileName and file-uID. On the one hand
       * this is needed for the correct use of vuetify's v-select
       * component (items are an array of objects with itemValue and itemText),
       * on the other hand it simplifies the file-user-assignment
       * and file deletion. Due to the fact that for the most actions
       * string-values and not base64-values are required, it is more
       * performant.
       * */
      rawItems: []
    };
  },
  computed: {
    selectedItems: {
      get() {
        this.$emit("input", this.items);
        return this.items;
      },
      set() {
        this.$emit("input", this.items);
        return this.items;
      }
    },
    items() {
      return this.getItems();
    },

    /**
     * Switches between hide/show. To put it more precisely,
     * switches between render or not. Comes as prop via
     * conf….json-file. Request boolean-value from store
     * in order to control the v-if (conditional rendering).
     * */
    activator() {
      return this.$store.getters[this.activate];
    }
  },
  methods: {
    /**
     * File deletion
     * */
    deleteFile(item, index) {
      if (
        confirm(
          this.$t(this.confirmDeleteFile).replace(
            "${dummy}",
            `${item.itemText}`
          )
        )
      ) {
        /**
         * Instead of event communication store action is directly
         * dispatched with payload. Module specific action is
         * injected via props from conf….json-files under the
         * respective fieldType "FileSelectList".
         * */
        let fileObj = this.rawItems.filter(
          obj => obj.uID === item.itemValue
        )[0];
        this.items.splice(index, 1);
        this.$emit("input", this.items);
        this.$store.dispatch(this.deleteFiles, fileObj);
        /**
         * Init Validation. Uncomment if a re-validation of the entire
         * module is wanted after the deletion of the last file.
         * */
        // if (this.required === true && this.items.length < 1) {
        //   this.$store.commit("global_validateStatus", "validateNow")
        // }
      }
    },

    /**
     * Gets the files corresponding to its related FileInput-component.
     * The getters are mostly set in the respective conf….json-file.
     * Here the original file objects are prepared for the list.
     * See reference => rawItems
     * */
    getItems() {
      if (this.name === "proofOfAreaFileList") {
        let id = this.$store.getters[
          "layingFarm/getTemporaryStableIDLayingFarm"
        ];
        let t = this.$store.getters[this.files](id);
        if (t !== undefined) {
          this.rawItems = t.files;
          return t.files.map(obj =>
            Object.assign(
              {},
              {
                itemText: obj.fileName,
                itemValue: obj.uID
              }
            )
          );
        } else return [];
      } else if (
        this.$route.name === "packing" ||
        this.$route.name === "dyehouse" ||
        this.$route.name === "feedmills"
      ) {
        this.rawItems = this.$store.getters[this.files](this.name);
        return this.$store.getters[this.files](this.name).map(obj =>
          Object.assign(
            {},
            {
              itemText: obj.fileName,
              itemValue: obj.uID
            }
          )
        );
      } else this.rawItems = this.$store.getters[this.files];
      return this.$store.getters[this.files].map(obj =>
        Object.assign(
          {},
          {
            itemText: obj.fileName,
            itemValue: obj.uID
          }
        )
      );
    }
  }
};
</script>

<style scoped></style>
