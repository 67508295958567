import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"hidden":_vm.hidden}},[(_vm.inputHeading !== null)?_c('h3',{staticClass:"mt-3 mb-3",domProps:{"textContent":_vm._s(_vm.$t(_vm.inputHeading))}}):_vm._e(),_c(VTextField,{ref:_vm.name,class:{ 'mb-6': _vm.pHint || _vm.error },attrs:{"name":_vm.name,"value":this.$route.name === 'company' &&
      this.name === 'companyName' &&
      this.$store.getters['auth/getUserName'] !== null
        ? this.$store.getters['auth/getUserName']
        : _vm.value,"label":_vm.$t(_vm.label),"hint":_vm.$t(_vm.hint),"persistent-hint":_vm.pHint,"placeholder":_vm.$t(_vm.placeholder),"append-icon":_vm.icon,"color":_vm.color,"required":_vm.required,"id":_vm.name,"error-messages":_vm.$t(_vm.error),"hasError":_vm.hasError,"aria-describedby":(_vm.name + "-input"),"error-count":"5","rules":_vm.computedRules,"rulesID":_vm.rulesID,"disabled":this.$route.name === 'company' && this.name === 'companyName'
        ? true
        : !_vm.editable},nativeOn:{"input":function($event){return _vm.$emit('input', $event.target.value)}}},[(_vm.tooltip)?_c('tooltip',{attrs:{"slot":"append-outer","tooltip":_vm.tooltip},slot:"append-outer"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }