import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0 || _vm.activator)?_c('div',[(_vm.inputHeading !== null)?_c('h3',{staticClass:"mt-3",domProps:{"textContent":_vm._s(_vm.$t(_vm.inputHeading))}}):_vm._e(),_c(VSelect,{ref:_vm.name,class:{ 'mb-6': _vm.hint || _vm.error },attrs:{"name":_vm.name,"items":_vm.items,"value":_vm.value,"item-text":"itemText","item-value":"itemValue","label":_vm.$t(_vm.label),"hint":_vm.$t(_vm.hint),"multiple":_vm.multiple,"color":_vm.color,"required":_vm.required,"chips":"","deletable-chips":"","persistent-hint":_vm.pHint,"return-object":"","error-count":"5","id":_vm.name,"error-messages":_vm.$t(_vm.error),"hasError":_vm.hasError,"aria-describedby":(_vm.name + "-input"),"menu-props":{ closeOnContentClick: true },"no-data-text":_vm.$t(_vm.noDataText),"disabled":!_vm.editable},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
var item = ref.item;
return [_c(VChip,_vm._g(_vm._b({attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteFile(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.itemText)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [[_c(VListItem,_vm._b({},'v-list-item',attrs,false),[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.itemText)}})],1),_c(VSpacer),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"primary"}},[_c(VIcon,_vm._g({on:{"click":function($event){return _vm.deleteFile(item)}}},{ on: on }),[_vm._v(" $mdiDelete ")])],1)],1)],1)]]}}],null,false,243166969),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},[(_vm.tooltip)?_c('tooltip',{attrs:{"slot":"append-outer","tooltip":_vm.tooltip},slot:"append-outer"}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }