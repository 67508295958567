<template>
  <v-btn
    :name="name"
    :color="color"
    :disabled="disabled"
    :id="id"
    :class="myClass"
    type="button"
    elevation="4"
    ripple
    block
    @click="
      $emit('closeModal', $event);
      $store.commit('global_validateStatus', null);
      $store.commit('global_clearError');
    "
  >
    <!--  @click="clickHandler"  -->
    <v-icon>{{ btnIcon }}</v-icon
    >{{ btnText }}
  </v-btn>
</template>

<script>
export default {
  name: "ButtonCloseModal",
  props: [
    "name",
    "value",
    "color",
    "btnText",
    "btnIcon",
    "myClass",
    "disabled",
    "id"
  ]
  // methods: {
  //   clickHandler() {
  //     this.$emit('closeModal', false);
  //     this.$emit('input', false)
  //   }
  // }
};
</script>
