<template>
  <v-banner
    :name="name"
    :value="!!$store.getters[date]"
    elevation="4"
    id="banner"
    :icon="icon"
    icon-color="success"
    class="my-4"
  >
    <strong>{{ $t(text) }}</strong> {{ $store.getters[date] }}
    <span v-if="!!$store.getters[updateDates]"
      ><br /><strong>{{ $t(updateText) }}</strong>
      {{ $store.getters[updateDates] }}</span
    >
  </v-banner>
</template>

<script>
export default {
  name: "InfoBanner",
  props: ["name", "text", "date", "updateText", "updateDates", "icon"]
};
</script>

<style scoped>
#banner {
  color: rgba(0, 0, 0, 0.6);
}
</style>
