<template>
  <div :hidden="hidden">
    <h2 v-text="$t(inputHead)" v-if="inputHead" class="mt-6" />
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <p v-text="$t(inputText)" v-if="inputText" />
    <v-text-field
      type="number"
      :name="name"
      :value="value"
      :label="$t(label)"
      :placeholder="$t(placeholder)"
      :color="color"
      :hint="$t(hint)"
      :persistent-hint="pHint"
      :class="{ 'mb-6': pHint || error }"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      :disabled="!editable"
      error-count="5"
      @input.native="$emit('input', $event.target.value)"
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-text-field>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
export default {
  name: "NumberInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "placeholder",
    "inputHead",
    "inputHeading",
    "inputText",
    "color",
    "tooltip",
    "hidden",
    "hint",
    "pHint",
    "error",
    "required",
    "hasError",
    "editable"
  ]
};
</script>

<style scoped>
p,
h2,
h3,
span {
  color: rgba(0, 0, 0, 0.6);
}
</style>
