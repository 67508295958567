<template>
  <div>
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <v-textarea
      :name="name"
      :value="value"
      :label="$t(label)"
      :hint="$t(hint)"
      :class="{ 'mb-6': hint || error }"
      :prepend-inner-icon="icon"
      :color="color"
      clearable
      filled
      auto-grow
      persistent-hint
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      :disabled="!editable"
      @click:clear="deleteValue"
      @input.native="$emit('input', $event.target.value)"
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-textarea>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";

export default {
  name: "TextareaInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "icon",
    "hint",
    "inputHeading",
    "color",
    "tooltip",
    "hasError",
    "error",
    "required",
    "editable"
  ],
  methods: {
    deleteValue() {
      // Delete input Value from 'formData' object. The 'clearable' option just deletes the visual input!
      this.$delete(this.$parent.formData, this.name);
    }
  }
};
</script>

<style scoped></style>
