<template>
  <div class="mb-6">
    <h2 v-text="$t(inputHead)" v-if="inputHead" class="mt-6 mb-3" />
    <h3 v-text="$t(inputHeading)" v-if="inputHeading" class="mt-3 mb-3" />
    <v-data-table
      :headers="computedHeaders"
      :items="items"
      :value="value"
      class="elevation-8"
      :locale="isoLocale"
      :mobile-breakpoint="mobileBreakpoint"
      :no-data-text="nodata"
      :show-expand="showExpand"
      :expanded="expanded"
      :dense="switchDense"
      :item-key="itemKeyUnique"
      disable-sort
      disable-pagination
      disable-filtering
      hide-default-footer
      single-expand
      @input.native="$emit('input', $event.target.value)"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ $t(addHeading) }}</v-toolbar-title>
          <v-dialog
            v-model="dialog"
            max-width="500px"
            scrollable
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                x-small
                fab
                elevation="3"
                class="ml-2"
                v-on="on"
                :disabled="
                  !$store.getters[editableGetter] && $route.name !== 'company'
                "
                @click="addItem"
              >
                <v-icon dark>$mdiPlus</v-icon>
              </v-btn>
            </template>
            <v-card
              :style="{ 'max-height': dialogHeight }"
              :tile="$vuetify.breakpoint.xsOnly"
            >
              <v-card-title>
                <span class="text-h5">{{ $t(formTitle) }}</span>
              </v-card-title>
              <v-card-text>
                <v-form v-if="$route.name === 'company'" v-model="isValid">
                  <form-builder
                    v-if="addedItem"
                    v-bind:schema="schema.dialogItems"
                    v-model="addedItem"
                    ref="compForm"
                    :editable="true"
                  />
                </v-form>
                <v-form v-else v-model="isValid">
                  <form-builder
                    v-if="editedItem"
                    v-bind:schema="schema.dialogItems"
                    v-model="editedItem"
                    ref="otherForm"
                    :editable="$store.getters[editableGetter]"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t(dialogActionAbort) }}
                </v-btn>
                <v-btn
                  :disabled="!isValid"
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  {{ $t(dialogActionSave) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- #header.<name> slot -->
      <template
        v-for="header in computedHeaders"
        v-slot:[`header.${header.value}`]="{ header }"
      >
        <!--        {{ $t(`${header.text}`) }}-->
        {{ $t(header.text) }}
      </template>

      <!-- #item.<name> slots -->

      <!-- display object-values' item-text (derived from SelectInput) as array-like with comma + space. -->
      <!-- #item.<name> where "name" is computed if items item-value is an Array, -->
      <!-- eg. LayingFarm > farmingType -->
      <template
        v-for="objectItem in filteredItemObjects"
        #[`item.${objectItem}`]="{ item }"
      >
        <span :key="objectItem">
          {{
            objectItem in item ? $t(checkType(item[objectItem]).join(", ")) : ""
          }}
        </span>
      </template>

      <!-- display boolean values as yes/no. -->
      <!-- #item.<name> where "name" is computed if -->
      <!-- items item-value is boolean -->
      <template
        v-for="upperItem in filteredItemBoolean"
        #[`item.${upperItem}`]="{ item }"
      >
        <span :key="upperItem">
          {{
            upperItem in item
              ? item[upperItem]
                ? $t(filterYes)
                : $t(filterNo)
              : ""
          }}
        </span>
        <!--        <v-icon :key="upperItem">
          {{ item[upperItem] ? "$mdiCheckboxMarked" : "$mdiCheckboxBlankOutline" }}
        </v-icon>-->
      </template>

      <!-- auto row numbering set via headers -->
      <template #item.index="{ item }">
        {{ items.indexOf(item) + 1 }}
      </template>

      <!-- address item slot -->
      <template #item.address="{ item }">
        <span v-if="item.streetNumber">{{ item.streetNumber }}</span>
        <span v-if="item.postalCode"> | {{ item.postalCode }}</span>
        <span v-if="item.location"> | {{ item.location }}</span>
      </template>

      <!-- #expanded-item slot used for table in table -->
      <template #expanded-item="{ headers, item }" v-if="showExpand">
        <td :colspan="headers.length" class="py-0 px-0">
          <v-data-table
            :headers="computedHeadersHidden"
            :items="[item]"
            :locale="isoLocale"
            :mobile-breakpoint="mobileBreakpoint"
            :no-data-text="nodataExpanded"
            :dense="switchDense"
            item-key="name"
            disable-sort
            disable-pagination
            disable-filtering
            hide-default-footer
            single-expand
          >
            <template #top>
              <h3 class="px-4 pt-4 grey--text font-weight-light">
                {{ $t(expandedItemHeading) }}
                {{
                  item.name ||
                    item.lfStableName ||
                    item.rStableName ||
                    item.preStableName
                }}
              </h3>
            </template>

            <template
              v-for="header in computedHeadersHidden"
              v-slot:[`header.${header.value}`]="{ header }"
            >
              {{ $t(header.text) }}
            </template>

            <!-- display array-values with comma + space. -->
            <!-- #item.<name> where "name" is computed if items -->
            <!-- item-value is an Array, eg. LayingFarm > disinfectants -->
            <template
              v-for="arrayItem in filteredItemArray"
              #[`item.${arrayItem}`]="{ item }"
            >
              <span :key="arrayItem">
                {{ arrayItem in item ? $t(item[arrayItem].join(", ")) : "" }}
              </span>
            </template>

            <!-- display object-values' item-text (derived from SelectInput) as array-like with comma + space. -->
            <!-- #item.<name> where "name" is computed if items item-value is an Array, -->
            <!-- eg. LayingFarm > farmingType -->
            <template
              v-for="objectLower in filteredItemObjects"
              #[`item.${objectLower}`]="{ item }"
            >
              <span :key="objectLower">
                {{
                  objectLower in item
                    ? $t(checkType(item[objectLower]).join(", "))
                    : ""
                }}
              </span>
            </template>

            <!-- display boolean values as yes/no. -->
            <!-- #item.<name> where "name" is computed -->
            <!-- if items item-value is boolean -->
            <template
              v-for="lowerItem in filteredItemBoolean"
              #[`item.${lowerItem}`]="{ item }"
            >
              <span :key="lowerItem">
                {{
                  lowerItem in item
                    ? item[lowerItem]
                      ? $t(filterYes)
                      : $t(filterNo)
                    : ""
                }}
              </span>
            </template>

            <!-- display filenames used in lfStables -->
            <!-- single-use slot, therefore not generic -->
            <template
              #item.proofOfAreaFileList="{ item }"
              v-if="item.files !== undefined"
            >
              <span>{{ filterFileNames(item.files).join(", ") }}</span>
              <!-- <span class="pre-formatted">{{ filterFileNames(item.files).join(",\n") }}</span> -->
            </template>

            <!-- nodata content table in #expanded-item slot -->
            <template #no-data>
              <span>{{ $t(nodataExpanded) }}</span>
            </template>
          </v-data-table>
        </td>
      </template>

      <!-- item action slot -->
      <!-- modal editable -->
      <template #item.action="{ item }">
        <template v-if="switchItemModal && switchItemEditableStatus(item)">
          <v-tooltip left="left">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small
                :class="
                  $route.name === 'usercompany' || $route.name === 'company'
                    ? 'mr-2 green--text'
                    : 'mr-2'
                "
                @click="switchItemEditAction(item)"
              >
                $mdiLeadPencil
              </v-icon>
            </template>
            <span>{{ $t(editTooltip) }}</span>
          </v-tooltip>
          <v-tooltip left="left">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small
                :class="
                  $route.name === 'usercompany' || $route.name === 'company'
                    ? 'red--text'
                    : ''
                "
                @click.stop="deleteItem(item)"
              >
                $mdiDelete
              </v-icon>
            </template>
            <span>{{ $t(deleteTooltip) }}</span>
          </v-tooltip>
        </template>
        <!-- modal not editable -->
        <template
          v-else-if="switchItemModal && switchItemNotEditableStatus(item)"
        >
          <v-tooltip left="left">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  small
                  :class="
                    $route.name === 'usercompany' || $route.name === 'company'
                      ? 'green--text'
                      : ''
                  "
                  @click="switchItemEditAction(item)"
                >
                  $mdiCheckCircle
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t(approvedTooltip) }}</span>
          </v-tooltip>
        </template>
      </template>

      <!-- nodata content slot -->
      <template #no-data>
        <span>{{ $t(nodata) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { isObject } from "@/helpers/helpers";
import { mapActions } from "vuex";

/* eslint-disable no-console */
export default {
  /**
   * Asynchronous import of FormBuilder as recursive component.
   * Avoids circular references between components.
   * */
  name: "DataTable",
  components: { FormBuilder: () => import("./FormBuilder") },
  props: [
    "schema",
    "name",
    "value",
    "headers",
    "nodata",
    "nodataExpanded",
    "addHeading",
    "expandedItemHeading",
    "confirmDeleteTabItem",
    "dialogOptionA",
    "dialogOptionB",
    "dialogActionAbort",
    "dialogActionSave",
    "showExpand",
    "inputHead",
    "inputHeading",
    "filterYes",
    "filterNo",
    "approvedTooltip",
    "editableGetter",
    "editTooltip",
    "deleteTooltip"
  ],

  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {},
    addedItem: {},
    defaultItem: {},
    expanded: [],
    isValid: true
  }),

  computed: {
    items: {
      get() {
        return this.value;
      }
    },

    mobileBreakpoint() {
      switch (this.$route.name) {
        case "layingfarm":
          if (this.name === "lfStables") {
            return 1165;
          } else return 930;
        case "rearer":
          return 930;
        case "usercompany":
          return 400;
        default:
          return 600;
      }
    },

    switchDense() {
      switch (this.$route.name) {
        case "layingfarm":
          return this.$vuetify.breakpoint.width < 1165;
        case "rearer":
          return this.$vuetify.breakpoint.width < 930;
        case "usercompany":
          return this.$vuetify.breakpoint.width < 400;
        default:
          return this.$vuetify.breakpoint.xsOnly;
      }
    },

    switchItemModal() {
      switch (this.$route.name) {
        case "usercompany":
          return true;
        case "company":
          return true;
        default:
          return true;
      }
    },

    /**
     * Do not alter! Would have a massive impact on UI and UX.
     * The field on each item object that designates a unique key.
     * The value of this property has to be unique for each item.
     * For all cases, except "lfStables" and "rStables" it is set
     * via conf.json-file as "name"-prop, which dynamically generates
     * a unique key/name.
     * */
    itemKeyUnique() {
      return this.name === "lfStables"
        ? "lfStableName"
        : this.name === "rStables"
        ? "rStableName"
        : "name";
    },

    dialogHeight() {
      return this.$vuetify.breakpoint.smAndUp ? "80vh" : "100%";
    },

    formTitle() {
      return this.editedIndex === -1 ? this.dialogOptionA : this.dialogOptionB;
    },

    /**
     * Multi-lingual table headers for non-expanded table rows.
     * The hidden-attribute of a header is declared in its
     * respective (or module-related) conf….json-file.
     * */
    // TODO: Should be refactored. Check doubled translation.
    // Maybe the new v-data-table-header component could solve this problem.
    computedHeaders() {
      let headers = this.headers.filter(header => header.hidden !== true);
      let z = [];
      headers.forEach(el => {
        if (el.hasOwnProperty("text")) {
          el.text = this.$t(el.text);
          z.push(el);
        } else z.push(el);
      });
      return z;
    },

    /**
     * Multi-lingual table headers for expanded table rows.
     * The hidden-attribute of a header is declared in its
     * respective (or module-related) conf….json-file.
     * */
    computedHeadersHidden() {
      // const t = this.$t.bind(this);
      let headers = this.headers.filter(header => header.hidden === true);
      let container = [];
      headers.forEach(el => {
        if (el.hasOwnProperty("text")) {
          el.text = this.$t(el.text);
          container.push(el);
        } else container.push(el);
      });
      return container;
    },

    /**
     * Start computed formatting for items in item-slots
     * Declarative names explaining the use-case.
     * */
    filteredItemArray() {
      return Array.from(
        this.items.reduce(
          (s, o) =>
            Object.keys(o).reduce(
              (t, k) => (Array.isArray(o[k]) ? t.add(k) : t),
              s
            ),
          new Set()
        )
      );
    },

    filteredItemBoolean() {
      return Array.from(
        new Set(
          this.items.flatMap(item =>
            Object.keys(item).filter(key => typeof item[key] === "boolean")
          )
        )
      );
    },

    filteredItemObjects() {
      /* eslint-disable no-unused-vars */
      const res = [];
      this.items.forEach(function(el) {
        for (const prop in el) {
          if (Array.isArray(el[prop]) && isObject(el[prop][0])) {
            res.push(prop);
          } else if (isObject(el[prop])) {
            res.push(prop);
          }
        }
      });
      return [...new Set(res)];
      /* eslint-disable no-unused-vars */
    },
    /** End computed formatting for items in item-slots */

    isoLocale() {
      return this.$store.getters["auth/getLang"] === "de"
        ? "de-DE"
        : this.$store.getters["auth/getLang"] === "en"
        ? "en-US"
        : this.$store.getters["auth/getLang"] === "nl"
        ? "nl-NL"
        : "de-DE";
    }
  },

  watch: {
    dialog: function(val) {
      val || this.close();
    }

    /*"$i18n.locale": function(newVal, oldVal) {
      console.log("locale change to", newVal, " from ", oldVal);
      console.log(this.$store.hasModule('company'));
      console.log(this.headers);
    }*/

    /*headers: {
      immediate: true,
      deep: true,
      handler(x) {
        Object.assign(this.headersNew, this.headers);
      }
    }*/
  },

  methods: {
    ...mapActions({
      addUserToken: "auth/addUserToken",
      registerCompany: "auth/registerCompany",
      deleteCompany: "auth/deleteCompany",
      preloadRequestType: "requestType/loadRequestTypeModuleData",
      addSingleLocationID: "company/addSingleLocationID",
      deleteFacility: "company/deleteFacility",
      addLfTempStableID: "layingFarm/addLfTempStableID",
      deleteFileLayingFarm: "layingFarm/deleteFileLayingFarm"
    }),

    switchItemEditableStatus(item) {
      switch (this.$route.name) {
        case "usercompany":
          return item.editable || !item.hasOwnProperty("editable");
        case "company":
          return item.editable || !item.hasOwnProperty("editable");
        default:
          return this.$store.getters[this.editableGetter];
      }
    },

    switchItemNotEditableStatus(item) {
      switch (this.$route.name) {
        case "usercompany":
          return !item.editable && item.hasOwnProperty("editable");
        case "company":
          return !item.editable && item.hasOwnProperty("editable");
        default:
          return !this.$store.getters[this.editableGetter];
      }
    },

    switchItemEditAction(item) {
      switch (this.$route.name) {
        case "usercompany":
          return this.companyToRoute(item);
        case "company":
          return this.prodTypeToRoute(item);
        default:
          if (this.$store.getters[this.editableGetter])
            return this.editItem(item);
      }
    },

    async checkRequestType() {
      return await this.preloadRequestType();
    },

    /**
     * Navigate via determination of item's
     * (company aka. user) name to dynamic route.
     * Only in userToCompany's companies table.
     * Uses the #item.action-slot.
     * Checks if this company has already data or not.
     * */
    async companyToRoute(itemObj) {
      this.addUserToken(itemObj);
      if (await this.checkRequestType()) {
        this.$router.push({ name: "company" }).catch(e => e);
      } else {
        this.$router.push({ name: "request" }).catch(e => e);
      }
    },

    /**
     * Navigate via determination of item's
     * productionType to dynamic route.
     * Only in company's location/facility table.
     * Uses the #item.action-slot.
     * */
    prodTypeToRoute(itemObj) {
      const z = [];
      Object.entries(itemObj).map(([prop, val]) =>
        [prop].filter(function(el) {
          if (el === "productionType") {
            z.push(val.itemValue);
          }
        })
      );
      /**
       * Determine dealers-status as 998. Formerly known as
       * GlobalFacilityType IDs: 16 ('Agent'), 26 ('Point of sale'),
       * 29 ('Trading firm') and 33 ('Animal Trader')
       *
       * Determine eggFoodFacility-status as 999. Formerly known as
       * GlobalFacilityType IDs: 5 ('Egg-product plant') and
       * 19 ('Food-processing industry')
       * */
      if (z[0] === 16 || z[0] === 26 || z[0] === 29 || z[0] === 33) {
        z[0] = 998;
      } else if (z[0] === 5 || z[0] === 19) {
        z[0] = 999;
      }
      let comp = this.$router.options.routes.filter(
        item => item.name === "company"
      )[0].children;
      const res = [];
      comp.forEach(function(el) {
        for (const prop in el) {
          if (z[0] === el[prop].type) {
            res.push(el.name);
          }
        }
      });
      this.addSingleLocationID(itemObj.locationID);
      let uID = itemObj.uID.toString();
      this.$router.push({ name: res[0], params: { uID: uID } }).catch(e => e);
    },

    /**
     * Check whether an item value consists of one single object
     * or is an array of objects. Returns values' item-text in an array.
     * Used in conjunction with computed "filteredItemObjects"
     * in item-slot
     * */
    checkType(val) {
      const res = [];
      if (Array.isArray(val) && isObject(val[0])) {
        val
          .map(({ itemValue, ...keepAttrs }) => keepAttrs)
          .forEach(item => res.push(...Object.values(item)));
        return res;
      } else if (isObject(val)) {
        res.push(val.itemText);
        return res;
      }
    },

    /**
     * Returns filenames. Used to display filenames in
     * #item.proofOfAreaFileList-slot (LayingFarm/lfStables)
     * */
    filterFileNames(val) {
      const res = [];
      val
        .map(({ fileName }) => ({ fileName }))
        .forEach(item => res.push(...Object.values(item)));
      return res;
    },

    /** Start methods for table editing */

    /**
     * Add and edit table-items. Sets the validateStatus in order to allow
     * validation for the input-dialog (modal). Sets the temporarily stableID,
     * due to the possibility of file-uploads during add- or edit-process.
     * */
    editItem(item) {
      this.$store.commit("global_validateStatus", "validateNow");
      if (this.name === "lfStables") {
        this.addLfTempStableID(item.stableID);
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    /**
     * Used to set an uID for the stables. Triggers a
     * formData-update before any user-induced save action
     * takes places. Mainly needed for lfStables-file-upload.
     * Sets the validateStatus in order to allow
     * validation for the input-dialog (modal)
     * */
    addItem() {
      this.$store.commit("global_validateStatus", "validateNow");
      if (this.name === "lfStables") {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.$emit("input", this.items);
        this.editedIndex = this.items.indexOf(this.editedItem);
        this.editedItem = Object.assign(this.editedItem, this.editedIndex);
        this.addLfTempStableID(this.editedItem.stableID);
      }
    },

    /**
     * Saves the added or edited table-item.
     * Delay is used because of event-communication.
     * */
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else if (this.$route.name === "company" && this.name === "locations") {
        this.items.push(this.addedItem);
      } else if (
        this.name === "companies" &&
        this.$route.name === "usercompany"
      ) {
        this.items.push(this.editedItem);
        this.registerCompany(this.editedItem.name);
      } else {
        this.items.push(this.editedItem);
      }
      this.$emit("input", this.items);
      if (this.$route.name === "company") {
        let tmp = JSON.parse(JSON.stringify(this.addedItem));
        setTimeout(() => {
          this.$emit("add-element", tmp);
        }, 500);
      }
      this.close();
    },

    /**
     * Delete table items. Depending on the route/module in use,
     * different actions take place, eG. if a location (module: company)
     * is deleted, all corresponding data, files, etc. have to be deleted.
     * Therefore the appropriate action is called via mapActions and the
     * appropriate "confirmDeleteTabItem"-text with its respective
     * translation ( => this.$t(this.confirmDeleteTabItem)) is used.
     * ${dummy} is a replace-pattern for pseudo string-literals defined in conf….json-file.
     * */
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (this.$route.name === "company") {
        if (
          confirm(
            this.$t(this.confirmDeleteTabItem).replace(
              "${dummy}",
              `${item.facilityName}`
            )
          ) &&
          this.items.splice(index, 1)
        ) {
          this.$emit("input", this.items);
          this.deleteFacility(item.locationID); //item.locationID is facilityToken
        }
      } else if (
        this.$route.name === "layingfarm" &&
        Object.prototype.hasOwnProperty.call(item, "files")
      ) {
        if (
          confirm(
            this.$t(this.confirmDeleteTabItem).replace(
              "${dummy}",
              `${item.lfStableName}`
            )
          ) &&
          this.items.splice(index, 1)
        ) {
          item.files.forEach(file => this.deleteFileLayingFarm(file));
          this.$emit("input", this.items);
        }
      } else if (
        this.$route.name === "rearer" &&
        Object.prototype.hasOwnProperty.call(item, "rStableName")
      ) {
        if (
          confirm(
            this.$t(this.confirmDeleteTabItem).replace(
              "${dummy}",
              `${item.rStableName}`
            )
          ) &&
          this.items.splice(index, 1)
        ) {
          this.$emit("input", this.items);
        }
      } else if (this.$route.name === "usercompany") {
        if (
          confirm(
            this.$t(this.confirmDeleteTabItem).replace(
              "${dummy}",
              `${item.name}`
            )
          ) &&
          this.items.splice(index, 1)
        ) {
          this.$emit("input", this.items);
          this.deleteCompany(item.token);
        }
      } else {
        confirm(
          this.$t(this.confirmDeleteTabItem).replace("${dummy}", `${item.name}`)
        ) && this.items.splice(index, 1);
        this.$emit("input", this.items);
      }
    },

    /**
     * Closes the add/edit-dialog-modal. Resets the validateStatus
     * in order to not disturb the UX, unless the user submit the form.
     * */
    close() {
      this.$store.commit("global_validateStatus", null);
      this.dialog = false;
      if (this.$route.name === "company") {
        this.addedItem = Object.assign({}, this.defaultItem);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
      this.editedIndex = -1;
    }
    /** End methods for table */
  }
};
/* eslint-disable no-console */
</script>
<style scoped>
h2,
h3 {
  color: rgba(0, 0, 0, 0.6);
}

/*.pre-formatted {
    white-space: pre;
  }*/
</style>
