<template>
  <v-tooltip
    v-bind="
      typeof tooltip === 'string' ? { left: true, label: 'tooltip' } : tooltip
    "
    v-model="show"
    :key="index"
    v-if="tooltip"
    transition="scale-transition"
    :open-on-hover="true"
    :open-on-click="false"
    :close-delay="150"
    :open-delay="150"
    :max-width="`${width}px`"
  >
    <template v-slot:activator="{ on }">
      <v-icon
        v-bind="tooltip"
        v-on="on"
        @mouseenter="show = !show"
        @mouseleave="show = !show"
      >
        {{ tooltip.icon }}
      </v-icon>
    </template>
    <span>
      {{ typeof tooltip === "string" ? tooltip : tooltip && $t(tooltip.label) }}
    </span>
  </v-tooltip>
</template>

<script>
/* eslint-disable no-console */
export default {
  props: ["tooltip"],
  data() {
    return {
      show: false,
      width: "",
      index: ""
    };
  },
  methods: {
    checkSizes() {
      if (document.getElementById("content")) {
        return document.getElementById("content").clientWidth;
      } else if (document.getElementById("submitForm")) {
        return document.getElementById("submitForm").clientWidth;
      } else return window.screen.availWidth;
    }
  },

  // updated() {},

  mounted() {
    let xwidth = this.checkSizes();
    // console.log(xwidth);
    this.width = xwidth * 0.8;
  }
};
</script>

<style lang="css" scoped></style>
