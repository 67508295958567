<template>
  <div :hidden="hidden">
    <h3
      v-text="$t(inputHeading)"
      v-if="inputHeading !== null"
      class="mt-3 mb-3"
    />
    <v-text-field
      :name="name"
      :value="
        this.$route.name === 'company' &&
        this.name === 'companyName' &&
        this.$store.getters['auth/getUserName'] !== null
          ? this.$store.getters['auth/getUserName']
          : value
      "
      :label="$t(label)"
      :hint="$t(hint)"
      :persistent-hint="pHint"
      :placeholder="$t(placeholder)"
      :append-icon="icon"
      :color="color"
      :class="{ 'mb-6': pHint || error }"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      @input.native="$emit('input', $event.target.value)"
      :rules="computedRules"
      :rulesID="rulesID"
      :disabled="
        this.$route.name === 'company' && this.name === 'companyName'
          ? true
          : !editable
      "
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-text-field>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import i18n from "../../plugins/i18n";

export default {
  name: "TextInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "placeholder",
    "inputHeading",
    "icon",
    "color",
    "hint",
    "pHint",
    "tooltip",
    "hidden",
    "rulesText",
    "rulesText2",
    "rulesID",
    "required",
    "error",
    "hasError",
    "editable"
  ],
  mounted() {
    if (
      this.$route.name === "company" &&
      this.name === "companyName" &&
      this.$store.getters["auth/getUserName"] !== null
    ) {
      this.$emit("input", this.$store.getters["auth/getUserName"]);
    }
  },
  computed: {
    computedRules() {
      return this.cleaner();
    }
  },
  methods: {
    cleaner() {
      if (this.rulesID === "1E6A4C426C3E98453BC31EB76EA2080A") {
        return [v => !!v || `${i18n.t(this.rulesText)}`];
      }
      if (this.rulesID === "53BC31EB76EA2080A1E6A4C426C3E984") {
        return [
          v => !!v || `${i18n.t(this.rulesText)}`,
          v => /^.{3}/.test(v) || `${i18n.t(this.rulesText2)}`
        ];
      }
    }
  }
};
</script>

<style scoped>
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
