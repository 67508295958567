<template>
  <div>
    <!--    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3 mb-3" />-->
    <v-expansion-panels
      v-model="panel"
      :name="name"
      focusable
      multiple
      :accordion="true"
    >
      <v-expansion-panel v-for="(schema, i) in schema" :key="i" class="mb-4">
        <v-expansion-panel-header ripple>
          <h3 v-text="$t(schema.title)" v-if="schema.title !== null" />
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="mt-4 mb-n4">
          <form-builder
            v-bind:schema="schema.panelItems"
            v-model.lazy="computedFormData"
            @update-form="$emit('input', $event)"
            :editable="editable"
          >
          </form-builder>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  /**
   * Asynchronous import of FormBuilder as recursive component.
   * Avoids circular references between components.
   * */
  name: "AccordionInput",
  components: { FormBuilder: () => import("./FormBuilder") },
  props: ["schema", "formData", "name", "value", "inputHeading", "editable"],
  data() {
    return {
      panel: []
    };
  },
  computed: {
    computedFormData: {
      get() {
        return this.$parent.formData;
      },
      set() {
        this.$parent.formData = this.value;
      }
    }
    // allPanels() {
    //   return this.panel = [...Array(Object.keys(this.schema.panelItems)).keys()].map((k, i) => i)
    // }
  },
  watch: {
    deep: true,
    immediate: true,
    value: function() {
      this.$parent.formData = this.value;
    }
  },
  methods: {
    all() {
      this.panel = [...Array(Object.keys(this.schema.panelItems)).keys()].map(
        (k, i) => i
      );
    },
    passToParent() {
      this.$emit("input", this.computedFormData);
    }
  }
};
</script>

<style scoped>
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
