<template>
  <div :hidden="hidden">
    <h3
      v-text="$t(inputHeading)"
      v-if="inputHeading !== null"
      class="mt-3 mb-3"
    />
    <v-text-field
      :name="name"
      :value="value"
      :label="$t(label)"
      :hint="$t(hint)"
      :persistent-hint="pHint"
      :placeholder="$t(placeholder)"
      :append-icon="icon"
      :color="color"
      :class="{ 'mb-6': pHint || error }"
      :required="manualRequired"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      :rules="computedRules"
      :rulesID="rulesID"
      :error="manError"
      :disabled="!editable"
      @input.native="$emit('input', $event.target.value)"
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-text-field>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Tooltip from "./Tooltip";
import i18n from "../../plugins/i18n";

export default {
  name: "PrintNumberInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "placeholder",
    "inputHeading",
    "icon",
    "color",
    "hint",
    "pHint",
    "tooltip",
    "hidden",
    "required",
    "error",
    "hasError",
    "rulesText",
    "rulesID",
    "editable"
  ],
  data() {
    return {
      manError: false,
      manRequired: false
    };
  },
  computed: {
    computedRules() {
      return this.cleaner();
    },
    manualRequired() {
      if (
        this.rulesID === "printnumberRuleSpecialCase" &&
        this.$parent.formData.newConstruction === false
      ) {
        return this.manRequired;
      } else return this.required;
    }
  },
  methods: {
    cleaner() {
      if (
        this.rulesID === "printnumberRuleSpecialCase" &&
        this.$parent.formData.newConstruction === false
      ) {
        this.$invalid = true;
        this.manRequired = true;
        this.manError = true;

        if (this.value !== undefined && this.value.length !== 0) {
          this.$invalid = false;
          this.manRequired = false;
          this.manError = false;
        } else return [v => !!v || `${i18n.t(this.rulesText)}`];
      }
    }
  }
};
</script>

<style scoped>
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
