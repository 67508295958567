<template>
  <div :hidden="hidden">
    <h3
      v-text="$t(inputHeading)"
      v-if="inputHeading !== null"
      class="mt-3 mb-3"
    />
    <p v-text="$t(inputText)" v-if="inputText" class="mt-3" />
    <v-file-input
      v-model="file"
      :name="name"
      :label="$t(label)"
      :hint="$t(hint)"
      :persistent-hint="pHint"
      :class="{ 'mb-6': pHint || error }"
      :append-icon="icon"
      :color="color"
      :accept="accept"
      chips
      clearable
      :multiple="multiple"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      :disabled="!editable"
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
      <v-btn
        fab
        x-small
        elevation="4"
        color="primary"
        slot="append-outer"
        @click="input"
      >
        <v-icon>$mdiUpload</v-icon>
      </v-btn>
    </v-file-input>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Tooltip from "./Tooltip";
import { hashCode } from "../../helpers/helpers";

export default {
  name: "FileInput",
  components: { Tooltip },
  props: [
    "name",
    "label",
    "inputHeading",
    "inputText",
    "icon",
    "color",
    "hint",
    "pHint",
    "tooltip",
    "multiple",
    "accept",
    "hidden",
    // "value",
    "addFiles",
    "required",
    "error",
    "hasError",
    "editable"
  ],

  data() {
    return {
      file: []
    };
  },

  methods: {
    /**
     * File/-s to store. Due to vuetifys' habit to change
     * the type of input (single object or array of objects)
     * automatically, type checking is be done in order to
     * stay with one function …
     * */
    input() {
      if (this.file !== null && this.file !== 0) {
        const tmp = [];
        if (this.file instanceof File) {
          tmp.push(this.file);
        } else {
          tmp.push(...this.file);
        }
        const files = [];
        tmp.forEach(item => {
          if (!Object.prototype.hasOwnProperty.call(item, "uID")) {
            item.uID = hashCode(Date.now() + Math.random().toString());
          }
          let reader = new FileReader();
          reader.readAsDataURL(tmp[0]);
          let baseFile = "";
          reader.onload = () => {
            baseFile = reader.result;
            let file = {
              fileName: item.name,
              fileType: item.type,
              fileSize: item.size,
              fileContent: baseFile,
              fieldName: this.name,
              uID: item.uID
            };
            if (
              this.$route.name === "layingfarm" &&
              this.name === "proofOfArea"
            ) {
              file.stableID = this.$store.getters[
                "layingFarm/getTemporaryStableIDLayingFarm"
              ];
            } else {
              file.stableID = 0;
            }
            files.push(file);
            // if (this.name === "proofOfArea") {
            //   // this.$store.dispatch('layingFarm/addFileLayingFarm', files);
            //   this.$store.dispatch(this.addFiles, files);
            // } else

            // this.$emit("push-file", files);
            // // this.$emit('input');
            /**
             * Instead of event communication store action is directly
             * dispatched with payload. Module specific action is
             * injected via props from conf….json-files under the
             * respective fieldType "FileInput".
             * */
            this.$store.dispatch(this.addFiles, files);
            /**
             * clear input afterwards
             * */
            this.file = [];
          };
        });
      }
    }

    // deleteChip(index) {
    //   // Prompt here with text if required
    //   this.file.splice(index, 1);
    //   this.$emit('input', this.file);
    // }
  }
};
</script>

<style scoped>
p,
h2,
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
