<template>
  <div>
    <component
      v-for="(field, index) in schema"
      :key="index"
      :name="field.name"
      :is="field.fieldType"
      :value="formData[field.name]"
      @input="updateForm(field.name, $event)"
      @add-element="$emit('add-element', $event)"
      @closeModal="$router.go(-1)"
      v-bind="field"
      :error="errorMessages[index]"
      :hasError="!!errorMessages[index]"
      :editable="editable"
    >
    </component>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { isObject } from "../../helpers/helpers";
import { validationRules } from "../../helpers/ValidationParser";
import NumberInput from "./NumberInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import TextareaInput from "./TextareaInput";
import RadioInput from "./RadioInput";
import FileInput from "./FileInput";
import DateInput from "./DateInput";
import AccordionInput from "./AccordionInput";
import ComboboxInput from "./ComboboxInput";
import DataTable from "./DataTable";
import CheckboxInput from "./CheckboxInput";
import ButtonInput from "./ButtonInput";
import ButtonCloseModal from "./ButtonCloseModal";
import AlertError from "../AlertError";
import AlertSuccess from "../AlertSuccess";
import PrintNumberInput from "./PrintNumberInput";
import FileSelectList from "./FileSelectList";
import InfoBanner from "../InfoBanner";
import AlertApproved from "@/components/AlertApproved";

export default {
  name: "FormBuilder",
  components: {
    NumberInput,
    SelectInput,
    TextInput,
    TextareaInput,
    RadioInput,
    FileInput,
    DateInput,
    AccordionInput,
    ComboboxInput,
    DataTable,
    CheckboxInput,
    ButtonInput,
    ButtonCloseModal,
    AlertError,
    AlertSuccess,
    AlertApproved,
    PrintNumberInput,
    FileSelectList,
    InfoBanner
  },
  props: {
    value: {
      type: [Object, Array, String],
      required: true
    },
    schema: {
      type: [Object, Array],
      required: true
    },
    editable: {
      type: [Boolean]
    }
  },

  data() {
    return {
      formData: this.value || {},
      schemaFieldsToValidate: {}
    };
  },

  computed: {
    errorMessages() {
      /* eslint-disable no-undef */
      const validations = this.$v.formData;
      if (this.$store.getters.get_global_validateStatus === "validateNow") {
        return Object.keys(this.schemaToValidate).reduce((messages, key) => {
          const rules = this.schemaToValidate[key].validations;
          const rulesKeys = Object.keys(this.schemaToValidate[key].validations);
          const validator = validations[key];
          if (!validator) return messages;
          for (const rule of rulesKeys) {
            if (validator[rule] !== false) continue;
            messages[key] = rules[rule].message;
            return messages;
          }
          return messages;
        }, {});
      } else return 0;
    },

    schemaToValidate() {
      this.getSchemaFieldsToValidate(this.schema, "toValidate");
      return this.schemaFieldsToValidate;
    }
  },

  watch: {
    deep: true,
    immediate: true,
    value: function() {
      this.formData = this.value;
    }
  },

  methods: {
    /**
     * Updates the formData-object. Listens to input-event.
     * */
    updateForm(fieldName, value) {
      if (fieldName === undefined) {
        this.$set(this.formData, fieldName, value);
        delete this.formData.undefined;
        this.$emit("update-form", this.formData);
      } else {
        this.$set(this.formData, fieldName, value);
        this.$emit("update-form", this.formData);
      }
    },

    /**
     * Gets recursively fields from schema which should be validated.
     * => see computed "schemaToValidate"
     * */
    getSchemaFieldsToValidate(obj, name) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (isObject(obj[key])) {
            this.getSchemaFieldsToValidate(obj[key], name);
          } else if (key === name && obj[key] === true) {
            let res = {};
            res[obj.name] = obj;
            Object.assign(this.schemaFieldsToValidate, res);
          }
        }
      }
    },

    /**
     * Validation call, used in Card.vue on submit,
     * and in modal-dialogs => table edit, …
     * */
    validateForm() {
      this.$v.$touch();
      this.announceStatus();
      this.$nextTick(() => this.focusFirstStatus());
    },

    announceStatus() {
      this.$emit("status", {
        invalid: this.$v.$invalid
      });
    },

    /** Focus first error, determined by its status "hasError" */
    focusFirstStatus(component = this) {
      if (component.hasError) {
        /** ExpansionPanels */
        if (component.$parent.$parent.expansionPanel) {
          component.$parent.$parent.expansionPanel.isActive = true;
          // component.$parent.$parent.expansionPanel.toggle()
          /** inside modal */
          if (this.$route.name !== "company") {
            this.$vuetify.goTo(component.$parent.$parent.expansionPanel, {
              container: "div.v-dialog",
              duration: 500,
              easing: "easeInOutCubic"
            });
            return true;
          } else
          /** END inside modal */
            this.$vuetify.goTo(component.$parent.$parent.expansionPanel, {
              duration: 500,
              easing: "easeInOutCubic"
            });
          return true;
        }
        /** END ExpansionPanels */

        /** inside modal */
        if (this.$route.name !== "company" && this.$route.name !== "request") {
          this.$vuetify.goTo(component, {
            container: "div.v-dialog",
            duration: 500,
            easing: "easeInOutCubic"
          });
          return true;
        } else
        /** END inside modal */
          this.$vuetify.goTo(component, {
            duration: 500,
            offset: 100,
            easing: "easeInOutCubic"
          });
        return true;
      }
      let focused = false;
      component.$children.some(childComponent => {
        focused = this.focusFirstStatus(childComponent);
        return focused;
      });
      return focused;
    }
  },

  /**
   * This is the vuelidate validation object as a function
   * */
  validations() {
    return { formData: validationRules(this.schemaToValidate) };
  }
};
</script>

<style scoped></style>
