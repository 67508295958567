import * as Validators from "vuelidate/lib/validators";
import * as CustomValidators from "../helpers/customValidators";
import { isObject } from "./helpers";

/* eslint-disable no-console */
export const validationRules = schema => {
  return Object.keys(schema).reduce((rules, elementName) => {
    const item = schema[elementName];
    if (!("validations" in item)) return rules;

    const validations = {};
    for (const rule in item.validations) {
      const params = item.validations[rule].params;

      if (params) {
        if (isObject(params)) {
          validations[rule] =
            Validators[rule](...Object.values(params)) ||
            CustomValidators[rule](...Object.values(params));
        } else {
          validations[rule] =
            Validators[rule](params) || CustomValidators[rule](params);
        }
      } else {
        validations[rule] = Validators[rule] || CustomValidators[rule];
      }
    }

    rules[elementName] = validations;
    return rules;
  }, {});
};
