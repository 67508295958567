<template>
  <div>
    <h3
      v-text="$t(inputHeading)"
      v-if="inputHeading !== null"
      class="mt-3 mb-3"
    />
    <p v-text="$t(inputText)" v-if="inputText" class="mt-3" />
    <v-combobox
      v-model="selectedItems"
      :name="name"
      :value="value"
      :items="items"
      :label="$t(label)"
      :hint="$t(hint)"
      persistent-hint
      :placeholder="$t(placeholder)"
      :color="color"
      :class="{ 'mb-6': hint || error }"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      multiple
      chips
      deletableChips
      clearable
      :disabled="!editable"
      @input.native="emitItem"
    >
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-combobox>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
export default {
  name: "ComboboxInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "label",
    "hint",
    "placeholder",
    "color",
    "tooltip",
    "inputHeading",
    "inputText",
    "items",
    "hasError",
    "error",
    "required",
    "editable"
  ],
  data() {
    return {
      chosenItems: []
    };
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.chosenItems.push(item);
        this.$emit("input", item);
      }
    }
  },
  methods: {
    emitItem() {
      // eslint-disable-next-line
      // console.log(this.value + `\n` + 'from combobox');
      this.$emit("input", this.value);
    }
  }
};
</script>

<style scoped></style>
