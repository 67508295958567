<template>
  <v-alert
    :name="name"
    v-if="!$store.getters[getter]"
    :value="!$store.getters[getter]"
    type="success"
    class="mb-6"
    elevation="3"
    transition="fade-transition"
    origin="center center"
    border="left"
    dismissible
    text
    prominent
  >
    {{ $t(approvedText) }}
  </v-alert>
</template>
<script>
export default {
  name: "AlertApproved",
  props: ["approvedText", "name", "getter"]
};
</script>
