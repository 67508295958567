<template>
  <div>
    <h2 v-text="$t(inputHead)" v-if="inputHead" class="mt-6" />
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <p v-text="$t(inputText)" v-if="inputText" class="mt-3 v-input" />
    <p v-html="$t(inputSpecial)" v-if="inputSpecial" />
    <v-radio-group
      :row="this.row"
      v-model.lazy="computedValue"
      :label="$t(label)"
      :class="{ 'mb-6': error }"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      :disabled="!editable"
    >
      <v-radio
        v-for="(item, idx) in items"
        :key="idx"
        :value="item.value"
        :label="$t(item.label)"
        :name="name"
        :color="color"
        ripple
        @input="$emit('input', $event.target.value)"
      >
      </v-radio>
      <tooltip slot="append" :tooltip="tooltip" v-if="tooltip" />
    </v-radio-group>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";

export default {
  name: "RadioInput",
  components: { Tooltip },
  props: [
    "name",
    "label",
    "items",
    "value",
    "color",
    "inputHead",
    "inputHeading",
    "inputText",
    "inputSpecial",
    "tooltip",
    "required",
    "error",
    "hasError",
    "editable"
  ],

  data() {
    return {
      picked: this.value
    };
  },
  computed: {
    row() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(item) {
        this.picked = item;
        this.$emit("input", item);
      }
    }
  }
};
</script>

<style scoped>
p,
h2,
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
