<template>
  <div>
    <h2 v-text="$t(inputHead)" v-if="inputHead" class="mt-6" />
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <p v-html="$t(inputText)" v-if="inputText" />
    <v-checkbox
      v-model="selectedItem"
      :value="itemValue"
      :label="$t(label)"
      :name="name"
      :color="color"
      ripple
      :true-value="trueValue"
      :false-value="falseValue"
      indeterminate
      :hide-details="!error"
      :required="required"
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :aria-describedby="`${name}-input`"
      error-count="5"
      :disabled="!editable"
      @input="$emit('input', $event.target.value)"
    >
      <!-- If an individual value instead of true/false is desired use :value="itemValue"/:true-value="trueValue"/
      :false-value="falseValue" and set the corresponding prop in conf-file to sth. else than null. -->

      <tooltip slot="append" :tooltip="tooltip" v-if="tooltip" />
    </v-checkbox>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";

export default {
  name: "CheckboxInput",
  components: { Tooltip },
  props: [
    "name",
    "label",
    "value",
    "itemValue",
    "falseValue",
    "trueValue",
    "color",
    "inputHead",
    "inputHeading",
    "inputText",
    "tooltip",
    "hideDetails",
    "required",
    "error",
    "hasError",
    "editable"
  ],

  // data() {
  //   return {
  //   };
  // },

  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(item) {
        if (item === null) {
          this.$emit("input", false);
        } else this.$emit("input", item);
      }
    }
  }
};
</script>

<style scoped>
p,
h2,
h3,
span {
  color: rgba(0, 0, 0, 0.6);
}
</style>
