import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[(_vm.inputHead)?_c('h2',{staticClass:"mt-6 mb-3",domProps:{"textContent":_vm._s(_vm.$t(_vm.inputHead))}}):_vm._e(),(_vm.inputHeading)?_c('h3',{staticClass:"mt-3 mb-3",domProps:{"textContent":_vm._s(_vm.$t(_vm.inputHeading))}}):_vm._e(),_c(VDataTable,{staticClass:"elevation-8",attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"value":_vm.value,"locale":_vm.isoLocale,"mobile-breakpoint":_vm.mobileBreakpoint,"no-data-text":_vm.nodata,"show-expand":_vm.showExpand,"expanded":_vm.expanded,"dense":_vm.switchDense,"item-key":_vm.itemKeyUnique,"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":"","single-expand":""},nativeOn:{"input":function($event){return _vm.$emit('input', $event.target.value)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t(_vm.addHeading)))]),_c(VDialog,{attrs:{"max-width":"500px","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"color":"primary","x-small":"","fab":"","elevation":"3","disabled":!_vm.$store.getters[_vm.editableGetter] && _vm.$route.name !== 'company'},on:{"click":_vm.addItem}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("$mdiPlus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{style:({ 'max-height': _vm.dialogHeight }),attrs:{"tile":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c(VCardText,[(_vm.$route.name === 'company')?_c(VForm,{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.addedItem)?_c('form-builder',{ref:"compForm",attrs:{"schema":_vm.schema.dialogItems,"editable":true},model:{value:(_vm.addedItem),callback:function ($$v) {_vm.addedItem=$$v},expression:"addedItem"}}):_vm._e()],1):_c(VForm,{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.editedItem)?_c('form-builder',{ref:"otherForm",attrs:{"schema":_vm.schema.dialogItems,"editable":_vm.$store.getters[_vm.editableGetter]},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}}):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t(_vm.dialogActionAbort))+" ")]),_c(VBtn,{attrs:{"disabled":!_vm.isValid,"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t(_vm.dialogActionSave))+" ")])],1)],1)],1)],1)]},proxy:true},_vm._l((_vm.computedHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),_vm._l((_vm.filteredItemObjects),function(objectItem){return {key:("item." + objectItem),fn:function(ref){
var item = ref.item;
return [_c('span',{key:objectItem},[_vm._v(" "+_vm._s(objectItem in item ? _vm.$t(_vm.checkType(item[objectItem]).join(", ")) : "")+" ")])]}}}),_vm._l((_vm.filteredItemBoolean),function(upperItem){return {key:("item." + upperItem),fn:function(ref){
var item = ref.item;
return [_c('span',{key:upperItem},[_vm._v(" "+_vm._s(upperItem in item ? item[upperItem] ? _vm.$t(_vm.filterYes) : _vm.$t(_vm.filterNo) : "")+" ")])]}}}),{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.indexOf(item) + 1)+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.streetNumber)?_c('span',[_vm._v(_vm._s(item.streetNumber))]):_vm._e(),(item.postalCode)?_c('span',[_vm._v(" | "+_vm._s(item.postalCode))]):_vm._e(),(item.location)?_c('span',[_vm._v(" | "+_vm._s(item.location))]):_vm._e()]}},(_vm.showExpand)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-0 px-0",attrs:{"colspan":headers.length}},[_c(VDataTable,{attrs:{"headers":_vm.computedHeadersHidden,"items":[item],"locale":_vm.isoLocale,"mobile-breakpoint":_vm.mobileBreakpoint,"no-data-text":_vm.nodataExpanded,"dense":_vm.switchDense,"item-key":"name","disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":"","single-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h3',{staticClass:"px-4 pt-4 grey--text font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t(_vm.expandedItemHeading))+" "+_vm._s(item.name || item.lfStableName || item.rStableName || item.preStableName)+" ")])]},proxy:true},_vm._l((_vm.computedHeadersHidden),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),_vm._l((_vm.filteredItemArray),function(arrayItem){return {key:("item." + arrayItem),fn:function(ref){
var item = ref.item;
return [_c('span',{key:arrayItem},[_vm._v(" "+_vm._s(arrayItem in item ? _vm.$t(item[arrayItem].join(", ")) : "")+" ")])]}}}),_vm._l((_vm.filteredItemObjects),function(objectLower){return {key:("item." + objectLower),fn:function(ref){
var item = ref.item;
return [_c('span',{key:objectLower},[_vm._v(" "+_vm._s(objectLower in item ? _vm.$t(_vm.checkType(item[objectLower]).join(", ")) : "")+" ")])]}}}),_vm._l((_vm.filteredItemBoolean),function(lowerItem){return {key:("item." + lowerItem),fn:function(ref){
var item = ref.item;
return [_c('span',{key:lowerItem},[_vm._v(" "+_vm._s(lowerItem in item ? item[lowerItem] ? _vm.$t(_vm.filterYes) : _vm.$t(_vm.filterNo) : "")+" ")])]}}}),(item.files !== undefined)?{key:"item.proofOfAreaFileList",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.filterFileNames(item.files).join(", ")))])]}}:null,{key:"no-data",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.nodataExpanded)))])]},proxy:true}],null,true)})],1)]}}:null,{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.switchItemModal && _vm.switchItemEditableStatus(item))?[_c(VTooltip,{attrs:{"left":"left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({class:_vm.$route.name === 'usercompany' || _vm.$route.name === 'company'
                  ? 'mr-2 green--text'
                  : 'mr-2',attrs:{"small":""},on:{"click":function($event){return _vm.switchItemEditAction(item)}}},on),[_vm._v(" $mdiLeadPencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.editTooltip)))])]),_c(VTooltip,{attrs:{"left":"left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VIcon,_vm._g({class:_vm.$route.name === 'usercompany' || _vm.$route.name === 'company'
                  ? 'red--text'
                  : '',attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_vm._v(" $mdiDelete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.deleteTooltip)))])])]:(_vm.switchItemModal && _vm.switchItemNotEditableStatus(item))?[_c(VTooltip,{attrs:{"left":"left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{class:_vm.$route.name === 'usercompany' || _vm.$route.name === 'company'
                    ? 'green--text'
                    : '',attrs:{"small":""},on:{"click":function($event){return _vm.switchItemEditAction(item)}}},[_vm._v(" $mdiCheckCircle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.approvedTooltip)))])])]:_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.nodata)))])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }