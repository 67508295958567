import { helpers } from "vuelidate/lib/validators";
import { getKeys } from "./helpers";

/* eslint-disable no-console */
/**
 * Check positive Int without separator
 * */
export const positiveIntWoSeparator = helpers.regex(
  "posIntWoSeparator",
  /^[0-9]*$/
);

/**
 * Check positive Int with with one decimal
 * */
export const positiveIntOneDecimal = helpers.regex(
  "posIntOneDecimal",
  /^[0-9]*(\.[0-9])?$/
);

export const katid = helpers.regex("katid", /^[A-Z0-9/\-.]*$/);

export const katidArray = arr => {
  let result;
  let rgx = /^[A-Z0-9/\-.]*$/;

  if (!arr || !arr.length) {
    result = true;
  } else result = !!arr.every(el => rgx.test(el));

  return result;
};

export const printcode = helpers.regex(
  "printcode",
  /^[0-3]-[A-Z]{2}-[0-9A-Z-]+$/
);

export const customEmail = helpers.regex(
  "customemail",
  /(^$|^(([A-Za-z0-9+\-_]+(\.[A-Za-z0-9+\-_]+)*)|(.[a-zA-Z0-9]))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([A-Za-z0-9-]+\.)+[a-zA-Z]{2,}))$)/
);

/**
 * Regex for postal codes
 * */
const countryRegex = {
  GlobalCountry_18: /^[0-9]{4}$/, //Belgium
  GlobalCountry_22: /^[0-9]{4}$/, //Bulgaria
  GlobalCountry_1: /^[0-9]{5}$/, //Germany
  GlobalCountry_17: /^[0-9]{4}$/, //Denmark
  GlobalCountry_24: /^[0-9]{5}$/, //Finland
  GlobalCountry_11: /^[0-9]{5}$/, //France
  GlobalCountry_19: /^[0-9]{3}\s[0-9]{2}$/, //Greece
  GlobalCountry_10: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/, //United Kingdom
  GlobalCountry_14: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[\s]?[0-9AC-FHKNPRTV-Y]{4}$/, //Republic of Ireland
  GlobalCountry_3: /^[0-9]{5}$/, //Italy
  GlobalCountry_21: /^[0-9]{5}$/, //Croatia
  GlobalCountry_23: /^[0-9]{4}$/, //Luxembourg
  GlobalCountry_4: /^(?:NL-)?([0-9]{4})\s([A-Z]{2})$/, //Netherlands
  GlobalCountry_12: /^[0-9]{4}$/, //Norway
  GlobalCountry_2: /^[0-9]{4}$/, //Austria
  GlobalCountry_20: /^[0-9]{2}-[0-9]{3}$/, //Poland
  GlobalCountry_27: /^[0-9]{4}-[0-9]{3}$/, //Portugal
  GlobalCountry_13: /^[0-9]{6}$/, //Romania
  GlobalCountry_6: /^[0-9]{6}$/, //Russia
  GlobalCountry_7: /^[0-9]{3}\s[0-9]{2}$/, //Sweden
  GlobalCountry_5: /^[0-9]{4}$/, //Switzerland
  GlobalCountry_25: /^[0-9]{3}\s[0-9]{2}$/, //Slovakia
  GlobalCountry_8: /^[0-9]{4}$/, //Slovenia
  GlobalCountry_16: /^[0-9]{5}$/, //Spain
  GlobalCountry_9: /^[0-9]{3}\s[0-9]{2}$/, //Czech
  GlobalCountry_28: /^[0-9]{5}$/, //Turkey
  GlobalCountry_15: /^[0-9]{4}$/ //Hungary
};

/**
 * Regex for VAT numbers
 * */
const vatRegex = {
  GlobalCountry_2: /^(AT)(U\d{8})$/, //Austria
  GlobalCountry_18: /^(BE)(\d{10})$/, //Belgium
  GlobalCountry_22: /^(BG)(\d{9,10})$/, //Bulgaria
  GlobalCountry_21: /^(HR)(\d{11})$/, //Croatia
  GlobalCountry_9: /^(CZ)(\d{8,10})$/, //Czech
  GlobalCountry_17: /^(DK)(\d{8})$/, //Denmark
  GlobalCountry_24: /^(FI)(\d{8})$/, //Finland
  GlobalCountry_11: /^(FR)([0-9A-Z]{2}\d{9})$/, //France
  GlobalCountry_1: /^(DE)(\d{9})$/, //Germany
  GlobalCountry_19: /^(EL|GR)(\d{9})$/, //Greece
  GlobalCountry_15: /^(HU)(\d{8})$/, //Hungary
  GlobalCountry_3: /^(IT)(\d{11})$/, //Italy
  GlobalCountry_23: /^(LU)(\d{8})$/, //Luxembourg
  GlobalCountry_4: /^(NL)([0-9A-Z+*]{12})$/, //Netherlands
  GlobalCountry_12: /^(NO)?[0-9]{9}(MVA)?$/, //Norway (country code and 'MVA' are not mandatory)
  GlobalCountry_20: /^(PL)[0-9]{10}$/, //Poland
  GlobalCountry_27: /^(PT)[0-9]{9}$/, //Portugal
  GlobalCountry_14: /^(IE)(\d)([A-Z0-9])(\d{5})([A-Z]{1,2})$/, //Republic of Ireland
  GlobalCountry_13: /^(RO)[0-9]{2,10}$/, //Romania
  GlobalCountry_6: /^(RU)(\d{10}|\d{12})$/, //Russia
  GlobalCountry_25: /^(SK)(\d{10})$/, //Slovakia
  GlobalCountry_8: /^(SI)(\d{8})$/, //Slovenia
  GlobalCountry_16: /^(ES)([A-Z0-9])(\d{7})([A-Z0-9])$/, //Spain
  GlobalCountry_7: /^(SE)(\d{12})$/, //Sweden
  GlobalCountry_5: /^(CHE)(\d{9})(IVA|TVA|MWST)?$/, //Switzerland
  GlobalCountry_28: /^(TR)(\d{10})$/, //Turkey
  GlobalCountry_10: /^(GB)(\d{9}|\d{12})$/ //United Kingdom
};

const postalCodeRegex = rx => countryRegex[rx];

const vatNumberRegex = rx => vatRegex[rx];

//Ignore IDE unused hint. Is used in confCompany.json.
export const zipCompanyAddress = (value, obj) => {
  return value === undefined || value === null || value === ""
    ? true
    : obj["caCountry"] !== undefined
    ? postalCodeRegex(obj["caCountry"].itemValue).test(value)
    : false;
};

//Ignore IDE unused hint. Is used in confCompany.json.
export const zipBillingAddress = (value, obj) => {
  return value === undefined || value === null || value === ""
    ? true
    : obj["baCountry"] !== undefined
    ? postalCodeRegex(obj["baCountry"].itemValue).test(value)
    : false;
};

//Ignore IDE unused hint. Is used in confCompany.json.
export const vatCompanyAddress = (value, obj) => {
  return value === undefined || value === null || value === ""
    ? true
    : obj["caCountry"] !== undefined
    ? vatNumberRegex(obj["caCountry"].itemValue).test(value)
    : false;
};

//Ignore IDE unused hint. Is used in confCompany.json.
export const vatBillingAddress = (value, obj) => {
  return value === undefined || value === null || value === ""
    ? true
    : obj["baCountry"] !== undefined
    ? vatNumberRegex(obj["baCountry"].itemValue).test(value)
    : false;
};

export function zipcode(value, obj) {
  let keys = getKeys(obj, value).filter(el => el.includes("PostalCode"));
  let result;

  if (value === undefined || value === null || value === "") {
    result = true;
  }

  if (keys.length > 0) {
    let substring = keys[0].replace("PostalCode", "");
    let countryCode = "";
    if (obj[keys[0]] === value && obj[substring + "Country"] !== undefined) {
      countryCode = obj[substring + "Country"].itemValue;
      result = postalCodeRegex(countryCode).test(value);
    } else result = true;
  }
  return result;
}
