<template>
  <div :hidden="hidden">
    <h2 v-text="$t(inputHead)" v-if="inputHead" class="mt-6" />
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <p v-text="$t(inputText)" v-if="inputText" class="mt-3" />
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :name="name"
          v-model="computedDateFormatted"
          v-on="on"
          readonly
          :persistent-hint="true"
          :prepend-icon="icon"
          :hint="$t(hint)"
          :placeholder="$t(placeholder)"
          :label="$t(label)"
          :class="{ 'mb-6': hint || error }"
          :required="required"
          :id="name"
          :ref="name"
          :error-messages="$t(error)"
          :hasError="hasError"
          :aria-describedby="`${name}-input`"
          error-count="5"
          :disabled="!editable"
        >
          <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
        </v-text-field>
      </template>
      <v-date-picker
        v-model="selected"
        :locale="$t(locale)"
        :required="true"
        first-day-of-week="1"
        value="ISO 8601"
      >
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="menu = false">
          <v-icon>$mdiClose</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="
            $refs.menu.save(selected);
            $emit('input', selected);
          "
        >
          <v-icon>$mdiCheckboxMarkedCircle</v-icon>
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
/* eslint-disable no-console */
export default {
  name: "DateInput",
  components: { Tooltip },
  props: [
    "name",
    "locale",
    "tooltip",
    "icon",
    "hint",
    "placeholder",
    "label",
    "hidden",
    "inputHead",
    "inputHeading",
    "inputText",
    "value",
    "required",
    "error",
    "hasError",
    "editable"
  ],

  data() {
    return {
      menu: false,
      date: null
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.selected);
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    }
  }
};
</script>

<style scoped>
p,
h2,
h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>
