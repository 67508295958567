<template>
  <div>
    <h3 v-text="$t(inputHeading)" v-if="inputHeading !== null" class="mt-3" />
    <v-select
      :name="name"
      :items="filteredItems"
      :value="value"
      item-text="itemText"
      item-value="itemValue"
      :label="$t(label)"
      :hint="$t(hint)"
      :multiple="multiple"
      :append-icon="icon"
      :color="color"
      :rules="computedRules"
      :required="required"
      :rulesID="rulesID"
      chips
      deletable-chips
      :persistent-hint="pHint"
      :class="{ 'mb-6': hint || error }"
      @input.native="$emit('input', $event.target.value)"
      v-model="selectedItems"
      return-object
      :id="name"
      :ref="name"
      :error-messages="$t(error)"
      :hasError="hasError"
      :disabled="!editable"
      :aria-describedby="`${name}-input`"
      :no-data-text="$t(noDataText)"
    >
      <template #item="{ item }">
        {{ $t(item.itemText) }}
      </template>
      <template #selection="{ item }">
        {{ $t(item.itemText) }}
      </template>
      <tooltip slot="append-outer" :tooltip="tooltip" v-if="tooltip" />
    </v-select>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import i18n from "../../plugins/i18n";
import { typeOf } from "@/helpers/helpers";
export default {
  name: "SelectInput",
  components: { Tooltip },
  props: [
    "name",
    "value",
    "text",
    "label",
    "multiple",
    "items",
    "itemText",
    "itemValue",
    "inputHeading",
    "icon",
    "color",
    "hint",
    "tooltip",
    "pHint",
    "rulesText",
    "required",
    "rulesID",
    "error",
    "hasError",
    "editable",
    "noDataText"
  ],

  data() {
    return {
      chosenItems: []
    };
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.chosenItems.push(item);
        this.$emit("input", item);
      }
    },
    filteredItems() {
      return this.getItems();
    },
    computedRules() {
      return this.cleaner();
    }
  },
  methods: {
    cleaner() {
      if (this.rulesID === "4A7D48C0DE48463AF8DA6A0CD51A8D96") {
        return [v => !!v || `${i18n.t(this.rulesText)}`];
      }
    },

    getItems() {
      if (typeOf(this.items) === "String") {
        /**
         * return this.$store.getters.get_global_countries; => does not work, as prop is a string
         * this.$store.getters["company/get_countries"] => could be a possibility to migrate to company module
         * this.$store.getter["get_global_countries"] => use bracket notation without module, to access rootStore
         * */
        return this.$store.getters[this.items];
      } else {
        return this.items;
      }
    }
  }
};
</script>

<style scoped></style>
