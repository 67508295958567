import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tooltip)?_c(VTooltip,_vm._b({key:_vm.index,attrs:{"transition":"scale-transition","open-on-hover":true,"open-on-click":false,"close-delay":150,"open-delay":150,"max-width":(_vm.width + "px")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g(_vm._b({on:{"mouseenter":function($event){_vm.show = !_vm.show},"mouseleave":function($event){_vm.show = !_vm.show}}},'v-icon',_vm.tooltip,false),on),[_vm._v(" "+_vm._s(_vm.tooltip.icon)+" ")])]}}],null,false,1544513625),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},'v-tooltip',
    typeof _vm.tooltip === 'string' ? { left: true, label: 'tooltip' } : _vm.tooltip
  ,false),[_c('span',[_vm._v(" "+_vm._s(typeof _vm.tooltip === "string" ? _vm.tooltip : _vm.tooltip && _vm.$t(_vm.tooltip.label))+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }