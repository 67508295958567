<template>
  <div :class="myClass">
    <v-btn
      :name="name"
      :color="color"
      :loading="this.$route.name === 'send' ? validatorLoading : loading"
      :type="type"
      :disabled="disabled"
      :id="id"
      ripple
      :rounded="rounded"
      elevation="4"
      @click="clickHandler"
    >
      <!--      @click="$emit('submitAction', $event)"-->
      <v-icon left>{{ btnIcon }}</v-icon
      >{{ $t(btnText) }}
    </v-btn>
    <p v-if="inputSubText" v-text="$t(inputSubText)" class="mt-3 mb-0" />
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from "vuex";

export default {
  name: "ButtonInput",
  props: [
    "name",
    "value",
    "color",
    "inputSubText",
    "btnText",
    "btnIcon",
    "myClass",
    "disabled",
    "type",
    "id",
    "rounded"
  ],
  data() {
    return {
      loader: null,
      loading: false
    };
  },
  computed: {
    ...mapState("validator", ["validatorLoading"])
  },
  methods: {
    clickHandler() {
      if (this.$route.name === "send") {
        this.$store.commit("validator/set_loading", true);
        return (this.loader = this.validatorLoading);
      } else {
        return (this.loader = "loading");
      }
    }
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 500);

      this.loader = null;
    }
  }
};
</script>

<style scoped>
p {
  color: rgba(0, 0, 0, 0.6);
}
</style>
